<template>
  <div class="pt-4 pb-md-2">
    <div class="row">
      <div class="col-md-6 d-flex flex-column justify-content-md-between ">
        <div class="order-md-last">
          <h3 class="fellpunzel-gold pb-1">Kontaktdaten</h3>

          <p>
            <a
              href="mailto:fellpunzel@web.de"
              target="_blank"
              class="fellpunzel-gold"
              ><b-icon icon="envelope" /> fellpunzel@web.de</a
            >
            <br />
            <a href="tel:+491784884448" target="_blank" class="fellpunzel-gold"
              ><b-icon icon="telephone" /> +491784884448</a
            >
          </p>

          <p>
            Geschäftsinhaberin: <br />
            Sabrina Wibranek
          </p>
        </div>
        <div>
          <h3 class="fellpunzel-gold pb-1">Adresse</h3>

          <a
            href="https://goo.gl/maps/93DdPhnqHG1kL86q8"
            target="_blank"
            class="d-flex d-inline-block fellpunzel-gold"
          >
            <ul class="list-unstyled d-inline-block">
              <li>Fellpunzel</li>
              <li>Hänflingstr. 7-9</li>
              <li>45472 Mülheim an der Ruhr</li>
            </ul>
          </a>
        </div>
        <div class="order-md-first">
          <h3 class="fellpunzel-gold pb-1 ">
            So finden sie Fellpunzel
          </h3>
          <p>
            Unser Hundesalon befindet sich ganz in der Nähe des
            Rhein-Ruhr-Zentrums und ist mit dem Auto sehr leicht auffindbar.
            <br />
            Für unsere Kunden sind 2 Parkplätze vor dem Geschäft reserviert.
          </p>
        </div>
      </div>

      <div class="container containerForMaps col-lg-6">
        <iframe
          class="responsive-iframe "
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.0424940221383!2d6.914333316041146!3d51.439013479623945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c22c99a0c9d7%3A0xa1c7796e2ff00fd3!2sH%C3%A4nflingstra%C3%9Fe%207%2C%2045472%20M%C3%BClheim%20an%20der%20Ruhr!5e0!3m2!1sde!2sde!4v1614437755453!5m2!1sde!2sde"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Kontzakt"
};
</script>
